<template>
  <v-container fluid>
    <FilterDrawer
      current-component="colors-filter"
      @filter="filterColors"
      @clear="clearFilter"
    />
    <GroupColor @setColorsSelected="setCurrentColor" />
    <v-alert
      v-if="successAdding"
      :value="successAdding"
      dense
      text
      type="success"
      transition="slide-x-transition"
      class="font-weight-bold"
    >
      {{ successText }}
    </v-alert>
    <Table :data="dataTable">
      <template v-slot:group="{ selectedItems }">
        <v-btn
          class="mx-2"
          color="primary"
          rounded
          @click="colorDrawner(selectedItems)"
        >
          Agrupar
          <v-icon right> fas fa-plus </v-icon>
        </v-btn>
      </template>
      <template v-slot:tbody="{ item }">
        <td class="text-center" style="cursor:pointer;">
          <ImageAvatar
            default-icon="fas fa-palette"
            :img_src="item.image_color"
          />
        </td>
        <td style="cursor:pointer;">{{ item.name }}</td>
        <td class="text-right" style="cursor:pointer;">
          {{ item.updated_at }}
        </td>
        <td class="text-center" style="cursor:pointer;">
          <StatusChip :current-status="item.status" />
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import checkProfileRoutePermission from '../../../mixins/checkProfileRoutePermission'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import ImageAvatar from '@/components/data-table/ImageAvatar'
import GroupColor from './GroupColor.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Table,
    ImageAvatar,
    StatusChip,
    FilterDrawer,
    GroupColor,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    successAdding: false,
    successText: 'Cor adicionada com sucesso',
    dataTable: {
      title: 'Cores',
      new: 'colors-new',
      edit: 'colors-edit',
      context: 'colors-context',
      massAction: 'colors-mass-action',
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        {
          text: 'Imagem',
          value: 'image_color',
          align: 'center',
          sortable: false,
          width: 50,
        },
        { text: 'Nome', value: 'name' },
        { text: 'Atualizado em', value: 'updated_at', align: 'end' },
        { text: 'Status', value: 'status', align: 'center' },
      ],
    },
  }),
  computed: {
    ...mapGetters('colors', ['colorDrawnerIsOpened']),
  },
  methods: {
    ...mapActions('colors', [
      'handleColorDrawner',
      'setColorsSelected',
      'groupColorsSelected',
    ]),
    async getColors() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      await this.$api
        .get('product_colors',{
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          }
        })
        .then((res) => {
          const mappedColor = res.data.data.map((color) => {
            return {
              ...color,
              name:
                color?.color_id === null
                  ? `${color.name} (Principal)`
                  : `${color.name} (Filha)`,
            }
          })
          this.dataTable.items = mappedColor
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterColors(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('product_colors/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    async setCurrentColor(data) {
      this.setColorsSelected([])
      this.handleColorDrawner({
        isOpened: false,
      })
      try {
        await this.groupColorsSelected(data)
        await this.getColors()
        this.successAdding = true
      } finally {
        setTimeout(() => {
          this.successAdding = false
        }, 4000)
      }
    },
    colorDrawner(colorsSelected) {
      this.handleColorDrawner({
        isOpened: true,
      })
      this.setColorsSelected(colorsSelected)
    },
    clearFilter() {
      this.getColors()
    },
  },
  created: function () {
    this.getColors()

    contextMenu.$once('action', (type) => {
      if (type === 'colors') {
        this.getColors()
      }
    })
    massAction.$once('action', () => {
      this.getColors()
    })
  },
}
</script>
